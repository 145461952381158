// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---node-modules-gatsby-plugin-offline-app-shell-js": () => import("/tmp/63e8e4e/node_modules/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---node-modules-gatsby-plugin-offline-app-shell-js" */),
  "component---src-templates-index-page-js": () => import("/tmp/63e8e4e/src/templates/IndexPage.js" /* webpackChunkName: "component---src-templates-index-page-js" */),
  "component---src-templates-content-page-js": () => import("/tmp/63e8e4e/src/templates/ContentPage.js" /* webpackChunkName: "component---src-templates-content-page-js" */),
  "component---src-pages-404-js": () => import("/tmp/63e8e4e/src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */)
}

exports.data = () => import(/* webpackChunkName: "pages-manifest" */ "/tmp/63e8e4e/.cache/data.json")

